import React from 'react'
import { 
  Wrapper,  
  ContentSection,
  Footer,
  ProjectContainer,
  CONSTANTS,
} from '../styled'
import { Header, ProjectCard } from '../'


const Projects = () => {
  const { projectData } = CONSTANTS
  return (
    <Wrapper>
      <Header activePage="projects" />
      <ContentSection>
        <ProjectContainer>
          {projectData.map(project => (
            <ProjectCard projectData={project} />
          ))}
        </ProjectContainer>
      </ContentSection>
      <Footer />
    </Wrapper>
  )
}

export default Projects