import styled from 'styled-components'
import { CONSTANTS } from '../'

/*
 *  Container for Project view
 */
export const ProjectContainer = styled.div`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

/*
/* Project Card Styles */
/**/
export const ProjectCardContainer = styled.div`
  height: 60vh;
  width: 28vw;
  display: grid;
  grid-template-rows: 1fr 32vh;
  margin-right: 2rem;

  @media (max-width: 480px) {
    margin-bottom: 2.5rem;
    margin-right: 0;
    width: 80vw;
    height: 60vh;
    grid-template-rows: 1fr 39vh;

    :nth-of-type(1) {
      margin-top: 5rem;
    }
  }
`

export const ProjectCardImage = styled.div`
  grid-row: 1;
  background: url(${props => props.defaultImage});
  background-size: contain;
  transition: all 2s ease-in-out;

  :hover {
    background: url(${props => props.hoverImage});
    background-size: contain;
  }
`

export const ProjectCardDescription = styled.div`
  grid-row: 2;
  background: ${CONSTANTS.colors.lightGrey};
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
`

export const ProjectCardDescriptionHeader = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  align-self: flex-end;
  margin: 0.5rem 0;
`

export const ProjectCardDescriptionParagraph = styled.span`
  line-height: 1.2;
  margin: 0.5rem 0;
`

export const ProjectCardDescriptionLabel = styled.span`
  font-weight: 700;
  font-size: 1.1em;
  margin-right: 0.25rem;
`

export const ProjectCardDescriptionIconContainer = styled.div`
  margin-top: 0.5rem;
  align-self: flex-end;
  padding: 0.5rem;
`

export const ProjectCardLink = styled.a`
  transition: all .4s ease-in-out;
  padding: 0 0.5rem;
  color: ${CONSTANTS.colors.darkGrey};

  &:hover {
    color: rebeccapurple;
    transform: scale(1.05);
    font-weight: 700;
  }

  &:visited {
    color: ${CONSTANTS.colors.darkGreen};
  }
`