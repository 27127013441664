import styled from 'styled-components'
import { CONSTANTS } from '../'

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 2px solid white;
`

export const AboutContent = styled.div`
  height: 70vh;
  width: 80vw;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 3rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`

export const Portrait = styled.div`
  display: grid;
  grid-column: 1;
  height: 80%;
  margin-top: 10%;
  background: url('images/profile.jpg');
  background-size: cover;

  @media (max-width: 480px) {
    margin-top: 3rem;
    height: 300px;
    width: 100%;
  }
`

export const ContentTextContainer = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
`

export const ContentTextHeader = styled.span`
  color: black;
  font-weight: 700;
  font-size: 1.2em;
  align-self: flex-end;
  margin-bottom: 1.5rem;
`

export const ContentLink = styled.a`
  text-decoration: none;
  transition: all 0.7s ease-in-out;
  
  :visited {
    color: rebeccapurple;
  }
  
  :hover {
    color: ${CONSTANTS.colors.darkGreen};
    font-weight: 700;
  }
`

export const ContentTextParagraph = styled.p`
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  line-height: 1.5;
`
