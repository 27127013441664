import styled from 'styled-components'
export const CONSTANTS = {
  colors: {
    golden: `#ffd400`,
    darkGreen: `#1E3231`,
    lightGrey: `#b6b8b6`,
    darkGrey: `#2f302f`,
  },
  fontStack: {
    main: `'Roboto', sans-serif`,
    header: `'Montserrat', sans-serif`,
  },
  projectData: [
    { 
      name: 'Cocktail Search Project',
      description: `
        This project was something I did to work with API calls through React and 
        develop some familiarity with displaying that data in a presentable, easy-to-use format.
      `,
      techStack: `React, Node.js`,
      mainImages: ['images/cocktail.jpg', 'images/cocktailhl.jpg'],
      deployUrl: 'https://cocktail-generator.herokuapp.com/',
      githubUrl: 'https://github.com/kevinconvery/cocktaildb-api-project',
    },
    {
      name: 'Scheduler App',
      description: `
        This project uses React to allow individual drivers to schedule tasks during their day, 
        and builds a grid of tasks based on the available data.
      `,
      techStack: `React`,
      mainImages: ['images/scheduler.png', 'images/scheduler.png'],
      deployUrl: `https://driver-scheduler-app.herokuapp.com/`,
      githubUrl: 'https://github.com/kevinconvery/scheduler-app',
    },
    {
      name: 'Serenity Therapeutics',
      description: `
        This static site built for a client was reworked using React and its styled-components library.
      `,
      techStack: `React`,
      mainImages: ['images/serenity.png', 'images/serenity.png'],
      deployUrl: `https://lkg-serenity.herokuapp.com/`,
      githubUrl: 'https://github.com/kevinconvery/lkg-serenity',
    },
  ],
}

export const Wrapper = styled.div`
  font-family: ${CONSTANTS.fontStack.main};
  height: 100vh;
  width: 100%;
  background: white;
  background-size: cover;
  display: grid;
  grid-template-rows: 10vh 80vh 10vh;

  media (max-width: 480px) {
    grid-template-rows: 20vh 60vh 20vh;
  }
`

export const ContentSection = styled.div`
  grid-row: 2;
  display: grid;
  place-items: center;
  
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    grid-row: 2 / 4;
  }
`

export const Footer = styled.div`
  grid-row: 3;
  background: ${CONSTANTS.colors.darkGreen};

  @media (max-width: 480px) {
    display: none;
  }
`
