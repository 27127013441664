import styled from 'styled-components'
import { CONSTANTS } from '../' 

export const LandingPageContainer = styled.div`
  background: url('images/bglanding.jpg');
  background-size: cover;
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    margin-top: 5vh;
    height: 120vh;
  }
`

export const LandingPageHeaderText = styled.span`
  color: white;
  font-family: ${CONSTANTS.fontStack.header};
  font-size: 3em;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 2em;
  }
`

export const LandingPageCaptionText = styled.span`
  color: white;
  font-family: ${CONSTANTS.fontStack.header};
  font-size: 2em;

  @media (max-width: 480px) {
    font-size: 1.33em
  }
`

export const LandingPageButtonRow = styled.div`
  margin-top: 5vh;
  width: 60vw;
  display: flex;
  justify-content: space-around;

  @media (max-width: 480px) {
    margin-bottom: 5vh;
    width: 80vw;
  }
`;

export const LandingPageLink = styled.a`
  border: 2px solid white;
  padding: 1rem 1.5rem;
  text-decoration: none;
  color: white;
  transition: all .4s ease-in-out;

  :hover {
    color: ${CONSTANTS.colors.golden};
  }
`
