import React from 'react'
import {  
  NavList, 
  NavItem, 
  HeaderSection,
  HeaderLogo,
  NavLink
} from '../styled'

const Header = props => {
  const { activePage } = props
  return (
    <HeaderSection>
      <HeaderLogo>
        KEVIN CONVERY
      </HeaderLogo> 
      <NavList>
        {activePage !== "about" && 
          <NavItem>
            <NavLink to="/about">About Me</NavLink>
          </NavItem>
        }
        {activePage !== "projects" &&
          <NavItem>
            <NavLink to="/projects">Projects</NavLink>
          </NavItem>
        }
        {activePage !== "home" &&
          <NavItem>
            <NavLink to="/">Home</NavLink>
          </NavItem>      
        }
      </NavList>
    </HeaderSection>
  )
}

export default Header