import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { CONSTANTS } from '../'

export const HeaderSection = styled.div`
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  grid-row: 1;
  background: ${CONSTANTS.colors.darkGreen};

  @media (max-width: 480px) {
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
`

export const HeaderLogo = styled.span`
  font-size: 2rem;
  font-family: ${CONSTANTS.fontStack.header};
  letter-spacing: .125rem;
  font-weight: 700;
  color: white;

  @media (max-width: 980px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 0.5rem;
  }
`

/* Nav section, used in Header component */
export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`
  
export const NavItem = styled.li`
  margin: 0 0.5rem;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  padding: .5rem 0;
`

export const NavLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  color: ${CONSTANTS.colors.golden};
  padding: 0.5rem 1rem;
  transition: all 0.7s ease-in-out;


  :visited {
    color: darkgrey;
  }

  :hover {
    color: black;
    transform: scale(1.05);
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`