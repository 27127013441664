import React from 'react'
import { 
  ProjectCardContainer, 
  ProjectCardImage, 
  ProjectCardDescription, 
  ProjectCardDescriptionHeader,
  ProjectCardDescriptionParagraph,
  ProjectCardDescriptionLabel,
  ProjectCardDescriptionIconContainer,
  ProjectCardLink,
} from '../styled'
import { FaGithub } from 'react-icons/fa'
import { MdLaunch } from 'react-icons/md'

const ProjectCard = props => {
  const { projectData } = props
  const { name, deployUrl, mainImages, description, githubUrl, techStack } = projectData

  return (
    <ProjectCardContainer>
      <ProjectCardImage
        defaultImage={mainImages[0]}
        hoverImage={mainImages[1]}
      />
      <ProjectCardDescription>
        <ProjectCardDescriptionHeader>{name}</ProjectCardDescriptionHeader>
        <ProjectCardDescriptionParagraph>{description}</ProjectCardDescriptionParagraph>
        <ProjectCardDescriptionParagraph>
          <ProjectCardDescriptionLabel>Tech Stack:</ProjectCardDescriptionLabel>
          {techStack}
        </ProjectCardDescriptionParagraph>
        <ProjectCardDescriptionIconContainer>
          <ProjectCardLink href={githubUrl} target="_blank" alt="Github page for code">
            <FaGithub />
          </ProjectCardLink>
          <ProjectCardLink href={deployUrl} target="_blank" alt="Deployed website">
            <MdLaunch />
          </ProjectCardLink>
        </ProjectCardDescriptionIconContainer>
      </ProjectCardDescription>
    </ProjectCardContainer>
  )
}

export default ProjectCard