import React from 'react'
import { LandingPage, About, Projects } from '../pages'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Container = () => (
  <Router>
    <Helmet>
      <title>Kevin Convery - Full Stack Developer</title>
      <meta charset="utf-8" />
      <meta name="viewport" content="width-device-width, initial-scale=1" />
      <meta name="description" content="Home page for Kevin Convery, full-stack developer. Available for freelance web design and development." />
      <link rel="canonical" href="http://kevinconvery.ca" />
    </Helmet>
    <Switch>
      <Route exact path="/">
        <LandingPage />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/projects">
        <Projects />
      </Route>
    </Switch>
  </Router>
)

export default Container