import React from 'react'
import { 
  Wrapper,  
  ContentSection,
  Footer,
  LandingPageContainer,
  LandingPageHeaderText,
  LandingPageCaptionText,
  LandingPageButtonRow,
  LandingPageLink,
} from '../styled'
import { Header } from '../'
import { FaGithub, FaLinkedin } from 'react-icons/fa'

const LandingPage = () => {
  return (
    <Wrapper>
      <Header activePage="home" />
      <ContentSection>
        <LandingPageContainer>
          <LandingPageHeaderText>KEVIN CONVERY</LandingPageHeaderText>
          <LandingPageCaptionText>FULL-STACK DEVELOPER</LandingPageCaptionText>
          <LandingPageButtonRow>
            <LandingPageLink href="https://www.github.com/kevinconvery">
              <FaGithub /> Github
            </LandingPageLink>
            <LandingPageLink href="https://www.linkedin.com/in/kevin-convery">
              <FaLinkedin /> LinkedIn
            </LandingPageLink>
          </LandingPageButtonRow>
        </LandingPageContainer>
      </ContentSection>
      <Footer />
    </Wrapper>
  )
}

export default LandingPage