import React from 'react'
import { 
  Wrapper,  
  Footer,
  ContentSection,
  Portrait,
  ContentTextContainer,
  ContentTextHeader,
  ContentTextParagraph,
  ContentLink,
  AboutContent
} from '../styled'
import { Header } from '../'

const About = props => {
  return (
    <Wrapper>
      <Header activePage="about" />
      <ContentSection>
        <AboutContent>
          <Portrait />
          <ContentTextContainer>
            <ContentTextHeader>
              ABOUT ME
            </ContentTextHeader>
            <ContentTextParagraph>
              I think I'm pretty interesting. I've had a pretty interesting career working with the federal government doing tax stuff and as a tradesman working on government and commercial buildings in downtown Toronto. I returned to technology, which is where my original education was, because I've always been interested in building interesting things that people will use. 
            </ContentTextParagraph>
            <ContentTextParagraph>
              I graduated from <ContentLink href="https://www.humber.ca" target="_blank">Humber College</ContentLink>'s computer programmer diploma program where I worked with programming languages like C++ and Java. More recently, I attended a full-stack bootcamp through <ContentLink href="https://www.lighthouselabs.ca" target="_blank">Lighthouse Labs</ContentLink> to modernize my skills with technologies like React, Node.js and Ruby on Rails.
            </ContentTextParagraph>
            <ContentTextParagraph>
              In my down time, you can generally find me either working on one of my little projects, cooking or reading a book and I really enjoy spending time with my wife of five years and our cat.
            </ContentTextParagraph>
          </ContentTextContainer>
        </AboutContent>
      </ContentSection>
      <Footer />
    </Wrapper>
  )
}

export default About